import { async } from "@firebase/util";
import { useResetPasscodeApi } from "api";
import { CHANGE_PASSCODE_INPUT_LIST } from "components/auth/signup/constants";
import { usePasscodeContext } from "context";
import { useCountdown, useIndexDb, useSteps } from "hooks";
import React, { createContext, ReactNode, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { APP_ROUTES } from "routes";
import { AppStorage, STORGAGE_KEYS } from "utils";
import { RESET_PASSCODE_OTP } from "../constants";

const { addToStore, getFromStore, removeFromStore } = new AppStorage();

const useResetPasscode = () => {
  const steps = useSteps(2);

  const hookForm = useForm();
  const navigate = useNavigate();

  const { getValues, handleSubmit } = hookForm;
  const { handleVerifyOtp, handleResetPasscode, isLoading, handleSendOtp } =
    useResetPasscodeApi();
  const { addVerifyPasscode, addSetPasscode } = usePasscodeContext();
  const {
    addToStore: addToIndexDb,
    getFromStore: getFromIndexDb,
    removeFromStore: removeFromIndexDb,
  } = useIndexDb();

  const resendTimeFromStore = getFromStore(STORGAGE_KEYS.RESEND_COUNTDONW);

  const countdown = useCountdown({ minutes: 1 });

  useEffect(() => {
    const canStillRun = (resendTimeFromStore ?? []).some((c: any) => c);
    if (canStillRun) {
      countdown.setTime(resendTimeFromStore);
    }
    countdown.start();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (() => {
      if (countdown.isRunning) {
        addToStore(STORGAGE_KEYS.RESEND_COUNTDONW, countdown.time);
      } else {
        removeFromStore(STORGAGE_KEYS.RESEND_COUNTDONW);
      }
    })();
  });

  const otpInputLists = useMemo(() => {
    return RESET_PASSCODE_OTP;
  }, []);

  const newPasswordInputLists = useMemo(() => {
    return CHANGE_PASSCODE_INPUT_LIST(getValues, true);
  }, [getValues]);

  const validateOtp = handleSubmit(async (data) => {
    await handleVerifyOtp({ code: data.otp }, async (response) => {
      const resetToken = response?.verifyResetPasscode?.resetToken;
      if (!!resetToken) {
        await addToIndexDb(STORGAGE_KEYS.RESET_TOKEN, resetToken);
        steps.nextStep();
      }
    });
  });

  const resetPasscode = handleSubmit(async (data) => {
    const resetToken = await getFromIndexDb(STORGAGE_KEYS.RESET_TOKEN);
    await handleResetPasscode(
      { newPasscode: data.newPasscode, resetToken: resetToken ?? "" },

      async (response) => {
        const successful = response?.confirmResetPasscode?.success;
        if (!!successful) {
          await removeFromIndexDb(STORGAGE_KEYS.RESET_TOKEN);
          await addVerifyPasscode(true);
          await addSetPasscode(true);

          navigate(APP_ROUTES.DASHBOARD);
        }
      }
    );
  });

  const resendOtp = async () => {
    await handleSendOtp(async (data: Record<string, any>) => {
      if (data?.resetPasscode?.success) {
        await removeFromStore(STORGAGE_KEYS.RESEND_COUNTDONW);
        countdown.reset();
        countdown.start();
      }
    });
  };
  return {
    steps,
    otpInputLists,
    newPasswordInputLists,
    hookForm,
    validateOtp,
    resetPasscode,
    isLoading,
    countdown,
    resendOtp,
  };
};

type ResetPasscodeContextProviderProps = ReturnType<
  typeof useResetPasscode
> & {};

const ResetPasscodeContext = createContext<ResetPasscodeContextProviderProps>(
  {} as ResetPasscodeContextProviderProps
);

export const ResetPasscodeContextProivder = ({
  children,
}: {
  children: ReactNode;
}) => {
  const resetPasscodeHook = useResetPasscode();

  return (
    <ResetPasscodeContext.Provider value={{ ...resetPasscodeHook }}>
      {children}
    </ResetPasscodeContext.Provider>
  );
};

export const useResetPasscodeContext =
  (): ResetPasscodeContextProviderProps => {
    const context = React.useContext(ResetPasscodeContext);
    if (context === undefined) {
      throw new Error("This must be used within a provider");
    }
    return context;
  };
