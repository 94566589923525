import { IRootState } from "./../../store/store";
import {
  IUserDetails,
  useLazyGetUserDetails,
} from "./../../api/user/get-user-details";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setUserData, updateUserData } from "store";
import {
  AppStorage,
  getEnvKey,
  getUrlQueryEntries,
  redirectUrl,
  decodeToken,
  isTokenExpired,
} from "utils";
import {
  firebaseLogout,
  getCurrentUser,
  getUserStatus,
  loginWithCustomToken,
} from "utils/firebase";
import { useNavigate } from "react-router-dom";
import { SCREEN_ROUTES } from "routes";
import { IUserBusinessDetails, useLazyGetUserBusinessDetails } from "api";
import { showToast } from "components/common";
import { VToastProps } from "components/common/types";
import useIndexDb from "hooks/useIndexDb";
import { usePasscodeContext } from "context";

const {
  getFromStore,
  getMultipleFromStore,
  addMultipleToStore,
  clearStore,
  addToStore,
} = new AppStorage();

export const useOauthCallBackHook = () => {
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const navigate = useNavigate();
  const { clearStore: clearIndexDB } = useIndexDb();

  const { addSetPasscode } = usePasscodeContext();

  const appEnvironment = getEnvKey("REACT_APP_VEEDEX_ENVIRONMENT");

  const [userBusiness, refetchUserBusiness] = useLazyGetUserBusinessDetails();

  const [isLoading, setIsLoading] = useState(true);
  const [result, executeQuery] = useLazyGetUserDetails();
  const { isAuthenticated } = useSelector((state: IRootState) => state.auth);

  const dispatch = useDispatch();

  const authenticateUser = (user: Record<string, any>) => {
    handleAddToToStore(user);
    dispatch(
      setUserData({
        isAuthenticated: true,
        userData: user,
        userToken: getFromStore("token"),
      })
    );
    setIsAuthenticating(false);
    setIsLoading(false);
  };

  const handleAddToToStore = (user: Record<string, any>) => {
    const previousUser = getFromStore("user");
    const newUser: IUserDetails = previousUser
      ? { ...previousUser, ...user }
      : user;

    addToStore("user", newUser);

    dispatch(
      setUserData({
        userData: user,
      })
    );
  };

  const handleLoginError = ({
    title = "Error signing user in",
    subText = "Please try again",
    type = "error",
  }: VToastProps) => {
    showToast({
      title,
      subText,
      type,
    });
  };

  const businessId = userBusiness?.data?.getBusinessDetail?._id;

  const userId = result?.data?.getUserDetail?._id;
  const kycStatus = result?.data?.getUserDetail?.kycStatus;

  useEffect(() => {
    const business = userBusiness?.data
      ?.getBusinessDetail as IUserBusinessDetails;
    const user = result?.data?.getUserDetail;

    if (result.data && userBusiness.data) {
      if (user && business) {
        authenticateUser({ ...user, business: business });
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, businessId, kycStatus]);

  useEffect(() => {
    if (userBusiness?.data || result?.data) {
      const business = userBusiness?.data?.getBusinessDetail;
      const userDetail = result?.data?.getUserDetail;

      dispatch(
        updateUserData({
          business: business ?? {},
          userDetail: userDetail ?? {},
        })
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userBusiness?.stale, result?.stale]);

  const getToken = async ({ code, state }: { code: string; state: string }) => {
    try {
      const hasMissingState = !code || !state;

      if (hasMissingState) {
        navigate(SCREEN_ROUTES.REDIRECT_UNAUTHENTICATED);
        handleLoginError({ title: "unauthorized" });
        console.error("has Missing state");
        return;
      }

      const platform =
        appEnvironment === "LOCAL" ? "VEEDEZX_WEB_LOCAL" : "VEEDEZX_WEB";

      const fetchTokenUrl = `${getEnvKey(
        "REACT_APP_GETTOKENBASEURL"
      )}/${code}?state=${state}&platform=${platform}&redirect_uri=${redirectUrl}`;

      // fetch backend token
      const fetchToken = await fetch(fetchTokenUrl);
      let tokenResponse = await fetchToken.json();
      let { token = null, isPasscodeSet = false } = tokenResponse;

      addSetPasscode(!!isPasscodeSet);

      // if there is no token , refresh window
      if (!token) {
        const invalidToken = ["invalid", "revoked", "expired"].some((data) =>
          (tokenResponse?.message?.toLowerCase() || "").includes(data)
        );
        if (invalidToken) {
          handleLoginError({ title: "Invalid Token" });
          navigate(SCREEN_ROUTES.REDIRECT_UNAUTHENTICATED);
        } else {
          setTimeout(() => {
            window?.top?.location.reload();
          }, 10000);
        }
      } else {
        // if there is token, use the token to login to backend
        await loginWithCustomToken(token)
          ?.then((firebaseToken) => {
            token = firebaseToken;
          })
          .catch((err) => {
            console.error(err);
            handleLoginError({ title: "Invalid Token" });

            firebaseLogout();
          });
        // get current user data
        const firebaseUserId = await getCurrentUser()?.uid;
        if (firebaseUserId) {
          addMultipleToStore({
            token,
            userId: firebaseUserId,
          });
          await checkIsAuthenticated();
        } else {
          return null;
        }
      }
    } catch (err) {
      console.error(err);
      handleLoginError({ title: "Invalid Token" });
    } finally {
    }
  };

  const checkIsAuthenticated = async (shouldReExecute = true) => {
    setIsAuthenticating(true);
    setIsLoading(true);
    try {
      let [token, userId, user] = getMultipleFromStore([
        "token",
        "userId",
        "user",
      ]);

      const tokenHasExpired = isTokenExpired(token);

      if (tokenHasExpired) {
        // Re assign token
        token = await getUserStatus();

        // Set the token to store
        addToStore("token", token);
      }

      const isValidToken = !!token && !isTokenExpired(token);

      // const isValidToken = checkExpiredToken(expiryDate ?? 0);
      const decodedToken = decodeToken(token ?? "");

      const canLogin = !!(decodedToken && userId && isValidToken);

      /* 
        if the user can login 
        spread the user data from local storage and token into the new user
        Add the new user to store 
        Fetch the user business and user data
      */
      if (canLogin) {
        if (user || decodedToken) {
          const newUser: IUserDetails = {
            ...(decodedToken ?? {}),
            ...(user ?? {}),
          };

          handleAddToToStore(newUser);
          await handleRefetchBusiness();
        }
        await executeQuery({ userId: userId });

        if (shouldReExecute) {
          await executeQuery({ userId: userId });
        }
        return true;
      } else {
        handleUserLogout();

        return false;
      }
    } catch (e) {
      handleLoginError({ title: "Invalid Token" });
      handleUserLogout();

      console.error({ error: e });
    }
  };

  const lazyUseGetToken = async () => {
    const { code, state } = getUrlQueryEntries();
    const hasSuccessfulRedirect = !!(code && state);

    if (hasSuccessfulRedirect && !isAuthenticated) {
      getToken({ code, state });
    } else {
      navigate(SCREEN_ROUTES.REDIRECT_UNAUTHENTICATED);
    }
  };

  const handleUserLogout = async () => {
    const nonce = getFromStore("nonce");
    clearStore();
    clearIndexDB();
    addToStore("nonce", nonce);
    firebaseLogout();
    dispatch(
      setUserData({
        isAuthenticated: false,
        userData: null,
        userToken: null,
      })
    );

    setIsAuthenticating(false);
    setIsLoading(false);
  };

  const handleRefetchBusiness = async () => {
    await refetchUserBusiness({
      businessId,
    });
  };

  return {
    getToken,
    isLoading,
    result,
    lazyUseGetToken,
    checkIsAuthenticated,
    isAuthenticating,
    handleUserLogout,
    authenticateUser,
    refetchUser: () => executeQuery({ userId: userId }),
    refetchUserBusiness,
    handleRefetchBusiness,
    userBusiness,
  };
};
